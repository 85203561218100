import React from "react"
import Helmet from "react-helmet"
import "../styles/styles.scss"

import profileImage from '../images/profile.jpg'
import downloadImage from '../images/Download.png'

// styles
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 370,
  color: "#ffffff"
}
const headingAccentStyles = {
  color: "#8158a6",
}
const paragraphStyles = {
  marginBottom: 48,
  fontSize: 15,
  color: "#ffffff",
}
const paragraphCodeStyles = {
  marginBottom: 10,
}
const paragraphStyles2 = {
  marginBottom: 15,
  fontSize: 20,
  color: "#ffffff",
}
const paragraphStyles3 = {
  marginBottom: 5,
  fontSize: 15,
  color: "#ffffff",
}
const paragraphStylesSmall = {
  //marginBottom: 48,
  fontSize: 10,
  color: "#ffffff",
}
const codeStyles = {
  marginBottom: 50,
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
// markup
const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <meta property='og:image' content="../images/profile.jpg" />
      </Helmet>
      <title>Minecraft Server</title>
      <h1 style={headingStyles}>
        You're invited!
        <br />
        <span style={headingAccentStyles}>— Join my Minecraft SMP server </span>
        <span role="img" aria-label="Party popper emojis">
          🎉🎉🎉
        </span>
      </h1>
      <div class="div1">
      <p style={paragraphStyles2}>
        IP address and version: 
      </p>
      <p style={paragraphCodeStyles}>
        <code style={codeStyles}>play.zenerism.com</code>
      </p>
      <p style={paragraphCodeStyles}>
        <code style={codeStyles}>Mincraft Java 1.19</code>{" "}
          <span role="img" aria-label="Sunglasses smiley emoji">
          😎
          </span>
      </p>
      </div>

      <div class="div2">
      <p style={paragraphStyles2}>
        <b>Notice!</b>
      </p>
      <p style={paragraphStyles3}>  
         This server has the Simple Voice Chat mod installed. 📣📣 To participate in proximity chat with other players, you will need to have it installed on your client.
      </p>
      <p style={paragraphStyles3}>
        <b>You can still join without installing it</b> but voice chat won't work.
      </p>
      </div>

      <h1 style={headingStyles}>
        <span style={headingAccentStyles}>— Download client with mods pre-installed. 💾</span>
      </h1>
      
      <p style={paragraphStyles2}>
        This download includes
      </p>
      <p style={paragraphStyles3}>
        the MultiMC launcher, Simple Voice Chat, and a few optimization mods.
      </p>
      <div class="div3">
      <a href="http://cloud.murtsa.dev/s/zisAtPsg3KQJRQD/download/MultiMC.zip" download>
        <img
          src={downloadImage}
          alt="Download"
          width="175"
         />
      </a>
      </div>

      <div class="div4">
      <p style={paragraphStyles3}>
        Extract the .zip file, run multimc.exe, and log in with your Minecraft account. The mods will be installed when you launch the game!
      </p>
      </div>

      <img
        alt="Logo"
        src={profileImage} 
        width="50"
        height="50"
      />
      <p style={paragraphStylesSmall}>
        <br/>{"Please don't share this information without my approval :)"}
      </p>
    </main>
  )
}

export default IndexPage
